import { useRef, useState } from "react";
import { Button, Paper, Container, Typography, Box, Grid, IconButton, Modal } from '@mui/material';
import { ExpandMore, DriveFileRenameOutline, PhoneInTalk, EnergySavingsLeaf, KeyboardDoubleArrowRight, HighlightOff, Facebook, Instagram } from '@mui/icons-material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Form } from "./components/form";
import { grey } from '@mui/material/colors';

const Header = () => {
  const logoImg = process.env.PUBLIC_URL + '/img/logo-new.png';
  return (
    <Box width="auto" sx={{ px: 3, py: 1, textAlign: 'center', maxWidth: { xs: 140, md: 200 } }}>
      <img src={logoImg} width='100%' alt='Compara Energia - Logo' />
    </Box>
  )
}

const Footer = () => {
  const logoImg = process.env.PUBLIC_URL + '/img/logo-new.png';
  const [modalOpenCredits, setModalOpenCredits] = useState(false);

  return (
    <>
      <Grid container backgroundColor='primary.background' py={5} textAlign='center'>
        <Grid item xs>
          <Typography component='p' variant='p' color='primary.text' sx={{ textAlign: "center" }}>Juntos srl - Via Trieste, 21 - 95127 Catania (CT) - P.iva 05849510879</Typography>
          <Typography component='p' variant='smallFooter' color='primary.text' sx={{ textAlign: "center", cursor: "pointer", mt: 2 }} onClick={() => setModalOpenCredits(true)}>Credits</Typography>
          <Typography component='p' variant='smallFooter' color='primary.text' sx={{ textAlign: "center", mt: 1 }}>Il presente servizio usa solo cookies di tipo tecnico per il corretto utilizzo del sito</Typography>
        </Grid>
      </Grid>

      <Modal
        open={modalOpenCredits}
        onClose={() => setModalOpenCredits(false)}
        aria-labelledby="modal-privacy-title"
        aria-describedby="modal-privacy-description"
        sx={{
          py: { xs: 1, md: 2 },
          backgroundColor: '#00000044',
        }}
      >
        <Paper elevation={4} sx={{ p: { xs: 2, md: 5 }, position: "relative", overflow: 'auto !important' }}>

          <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={() => setModalOpenCredits(false)}><HighlightOff /></IconButton>

          <Typography component='p' variant='p' color='primary.text' sx={{ textAlign: "center", cursor: "pointer" }}>
            Foto di <a href="https://unsplash.com/it/@rishabhdharmani?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Rishabh Dharmani</a> su <a href="https://unsplash.com/it/foto/foto-in-scala-di-grigi-di-una-persona-che-tiene-in-mano-una-candela-accesa-vU8kM8B_Giw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noreferrer">Unsplash</a>
          </Typography>

          <Typography component='p' variant='p' color='primary.text' sx={{ textAlign: "center", cursor: "pointer" }}>
            Foto di <a href="https://unsplash.com/it/@silverkblack?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Vitaly Gariev</a> su <a href="https://unsplash.com/it/foto/una-donna-seduta-a-una-scrivania-che-usa-un-computer-portatile-KwWY-gVlAvA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target="_blank" rel="noreferrer">Unsplash</a>
          </Typography>

        </Paper>
      </Modal>

    </>
  )
}

function App() {
  const bgImg = process.env.PUBLIC_URL + '/img/bg-intro.jpg';
  const refForm = useRef(null)

  const baseTheme = useTheme();
  let theme = createTheme({
    ...baseTheme,
    typography: {
      fontFamily: [
        // 'Roboto',
        '"Helvetica Neue"',
        'sans-serif',
        '-apple-system',
      ].join(','),
      h1: {
        fontSize: '4em',
        fontWeight: 'bolder',
        textTransform: 'uppercase',
        letterSpacing: 4
      },
      h2: {
        fontSize: '1.5em',
        fontWeight: '500',
        textShadow: '0 0 10px #000',
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1.2em',
        },
      },
      h3: {
        fontSize: '1.3em',
        fontWeight: '500',
        textTransform: 'uppercase',
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '1.2em',
        },
      },
      p: {
        lineHeight: 1.5
      },
      mainTitle: {
        fontSize: '4em',
        fontWeight: 'bolder',
        textTransform: 'uppercase',
        letterSpacing: 4,
        [baseTheme.breakpoints.down('sm')]: {
          fontSize: '2.5em',
        },
        '& span::after': {
          content: '""',
          background: "#0DCAF2",
          padding: "0 20px",
          display: "block",
          width: "100%",
          height: "20px",
          position: "absolute",
          left: "-20px",
          bottom: "2px",
          zIndex: "-1",
          transform: 'rotate(-2deg)',
          [baseTheme.breakpoints.down('sm')]: {
            height: "15px",
            padding: "0 15px",
            left: "-15px",
          }
        }
      },
      smallFooter: {
        fontSize: '0.8em'
      }
    },
    palette: {
      primary: {
        light: grey[300],
        main: grey[600],
        background: grey[100],
        text: grey[900]
      },
      secondary: {
        main: grey[900],
        background: grey[900],
        text: grey[50],
      },
      accent: {
        main: "#FFCA00",
        dark: '#BF821B',
        background: "#C0971B",
        text: "#000"
      }
    },
    components: {
      ...baseTheme.components,
      MuiLink: {
        variants: [{
          props: { variant: 'link-small' },
          style: {
            cursor: "pointer",
            fontSize: '0.7em'
          },
        }]
      },
      MuiTypography: {
        variants: [{
          props: { variant: 'privacy-description' },
          style: {
            fontSize: '0.9em',
            // wordBreak: 'break-all'
          },
        }]
      }
    }
  });

  document.title = `${process.env.REACT_APP_TITLE}`;
  return (
    <ThemeProvider theme={theme}>
      <Box>

        <Box sx={{ minHeight: '100vh', backgroundColor: "primary.background", backgroundImage: `url(${bgImg})`, backgroundSize: "cover", backgroundPosition: "center" }} display='flex' alignItems='center' flexDirection='column' justifyContent='space-between'>
          <Header />
          <Box>
            <Container maxWidth="md" sx={{ px: 3 }}>
              <Typography color='accent.main' component='h1' variant='mainTitle' sx={{ textAlign: "center" }}>Trova la tariffa più<br />
                <span style={{ position: 'relative', zIndex: 1, display: 'inline-block' }}>conveniente</span></Typography>
              {/* <Typography color='secondary.text' component='h1' variant='mainTitle' sx={{ textAlign: "center" }}>Compariamo<br />energia</span></Typography> */}
              <Typography component='h2' variant='h2' color='secondary.text' sx={{ textAlign: "center", mt: 10 }}>Cerchi un'offerta luce e gas più economica? Con il nostro comparatore, trova subito la tariffa perfetta per te tra oltre 80 fornitori. Facile, veloce e senza impegno.</Typography>
              <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' gap={1} mt={3}>
                <Button
                  variant="contained"
                  color="accent"
                  endIcon={<KeyboardDoubleArrowRight />}
                  onClick={() => refForm.current.scrollIntoView()}
                  size="large"
                >
                  Inizia subito
                </Button>
                {/* <Typography component='h2' variant='h2' color='accent.text' sx={{ textAlign: "left", cursor: "pointer" }} onClick={() => refForm.current.scrollIntoView()}>Inizia subito</Typography>
                <IconButton aria-label="contact" color='secondary' onClick={() => refForm.current.scrollIntoView()}>
                  <KeyboardDoubleArrowRight />
                </IconButton> */}
              </Box>
            </Container>
          </Box>
          <Box display='flex' justifyContent='stretch' alignItems='stretch'>
            <ExpandMore sx={{ fontSize: 50 }} />
          </Box>
        </Box>

        <Box my={20}>
          <Container maxWidth="lg" sx={{ px: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm textAlign='center'>
                <Paper elevation={4} sx={{ p: 5 }}>
                  <DriveFileRenameOutline sx={{ fontSize: 60, color: 'accent.main' }} />
                  <Typography component='p' variant='h3' color='primary.text' sx={{ textAlign: "center" }} my={3}>Compila il Modulo</Typography>
                  <Typography component='p' variant='p' color='primary.text' sx={{ textAlign: "justify" }}>Più dettagli ci fornirai sui tuoi consumi, meglio potremo adattare l'offerta alle tue necessità. Un'offerta personalizzata è sinonimo di massimo risparmio.</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm textAlign='center'>
                <Paper elevation={4} sx={{ p: 5 }}>
                  <PhoneInTalk sx={{ fontSize: 60, color: 'accent.main' }} />
                  <Typography component='p' variant='h3' color='primary.text' sx={{ textAlign: "center" }} my={3}>Sentiamoci</Typography>
                  <Typography component='p' variant='p' color='primary.text' sx={{ textAlign: "justify" }}>Massima trasparenza. Ti offriamo una consulenza gratuita per aiutarti a scegliere l'offerta energetica più conveniente per te. Senza impegno. Senza contratto.</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm textAlign='center'>
                <Paper elevation={4} sx={{ p: 5 }}>
                  <EnergySavingsLeaf sx={{ fontSize: 60, color: 'accent.main' }} />
                  <Typography component='p' variant='h3' color='primary.text' sx={{ textAlign: "center" }} my={3}>La scelta è tua</Typography>
                  <Typography component='p' variant='p' color='primary.text' sx={{ textAlign: "justify" }}>Trova il fornitore perfetto per te e le tue abitudini di consumo. Risparmia sulla tua bolletta.</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Grid container sx={{ minHeight: { lg: '70vh' } }}>
          <Grid item xs={12} md sx={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/comparatore-energia-01.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center', height: { xs: '50vh', md: 'unset' } }}>
          </Grid>
          <Grid item xs={12} md sx={{ backgroundColor: 'accent.main' }} display='flex' justifyContent='center' alignItems='center'>
            <Box p={{ xs: 10, md: 7, lg: 10 }}>
              <Typography component='p' variant='h5' color='accent.text' sx={{ textAlign: "left" }}>Cerchi un'offerta luce e gas su misura? Confrontiamo per te le tariffe di tutti i fornitori e ti aiutiamo a risparmiare sulla tua bolletta. Grazie a un'analisi dettagliata dei tuoi consumi, ti proponiamo soluzioni personalizzate per ottimizzare la tua spesa energetica. Semplifica la tua vita e scegli l'energia più adatta alle tue esigenze.</Typography>
              <Box display='flex' flexDirection='row' alignItems='center' gap={1} mt={3}>
                <Typography component='p' variant='h5' color='accent.text' sx={{ textAlign: "left", cursor: "pointer", fontWeight: 'bold' }} onClick={() => refForm.current.scrollIntoView()}>Inizia a risparmiare oggi stesso</Typography>
                <IconButton aria-label="contact" color='accent.text' onClick={() => refForm.current.scrollIntoView()}>
                  <KeyboardDoubleArrowRight />
                </IconButton>
              </Box>
              <Typography component='p' variant='h5' color='accent.text' sx={{ textAlign: "left", mt: 3 }}>Ti chiameremo entro 10 minuti dalla tua richiesta.</Typography>
            </Box>
          </Grid>
        </Grid>

        <Box my={10} ref={refForm}>
          <Container maxWidth="xl" sx={{ py: 3 }}>
            <Form />
          </Container>
        </Box>

        <Box my={10}>
          <Container maxWidth="xl" sx={{ py: 3, textAlign: 'center' }}>
            <Typography component='h5' variant='h5' sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2 }}>Seguici su</Typography>
            <IconButton onClick={() => window.open('https://www.facebook.com/comparatoreenergia/', '_blank').focus()}>
              <Facebook fontSize="large" color="secondary" />
            </IconButton>
            <IconButton onClick={() => window.open('https://www.instagram.com/compara.energia/', '_blank').focus()}>
              <Instagram fontSize="large" color="secondary" />
            </IconButton>
          </Container>
        </Box>

      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default App;