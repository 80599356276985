import { useState, useEffect, useRef } from "react";
import { Grid, Button, Paper, Container, Typography, Box, TextField, FormGroup, FormControlLabel, Checkbox, Link, Modal, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { HighlightOff, ThumbUpOffAlt, WarningAmber } from '@mui/icons-material';
import axios from "axios";
import HCaptcha from '@hcaptcha/react-hcaptcha';
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const TEL_REGEX = "([0-9]{8,12})";

export const Form = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [validEmail, setValidEmail] = useState(null);
    const [validTel, setValidTel] = useState(null);
    const [enableSend, setEnableSend] = useState(false);
    const [sendingStatus, setSendingStatus] = useState(false);
    const [sendResponse, setSendResponse] = useState({});
    const [telefonoVal, setTelefonoVal] = useState(false);
    const [privacyVal, setPrivacyVal] = useState(false);
    const [newsletterVal, setNewsletterVal] = useState(false);
    const [marketingVal, setMarketingVal] = useState(false);
    const [captchaVal, setCaptchaVal] = useState(false);
    const [ipAddress, setIpAddress] = useState(null);
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const nomeRef = useRef(null);
    const cognomeRef = useRef(null);
    const emailRef = useRef(null);
    const telefonoRef = useRef(null);
    const privacyRef = useRef(null);
    const newsletterRef = useRef(null);
    const marketingRef = useRef(null);
    const logoImg = process.env.PUBLIC_URL + '/img/logo-new-v2.png';

    useEffect(() => {
        if (!ipAddress) {
            axios.get(`https://api.ipify.org`)
            .then((d) => {
                // console.log(d.data);
                if (d.status ===  200)
                    setIpAddress(d.data);
            }).catch((e) => {
                // alert("Si è verificato un errore");
            });
        }
    }, [])

    useEffect(() => {
        if (telefonoVal && privacyVal && captchaVal)
            setEnableSend(true)
        else
            setEnableSend(false)
    }, [telefonoVal, privacyVal, captchaVal])

    function handleClick() {
        setSendingStatus(true)
        // console.log(emailRef.current.value);
        const checkValidation = new Promise((resolve, reject) => {
            if (!privacyVal)
                reject({ privacy: 'ko' })
            // if (!emailRef.current.value)
            //     reject({ email: 'ko' })
            if (!captchaVal)
                reject({ captcha: 'ko' })
            resolve()
        });

        checkValidation.then(() => {
            // console.log("Validazione ok");

            // Invia i dati per il salvataggio su DB
            axios.post(`${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_API_ENDPOINT}/records/leads`, {
                nominativo: `${nomeRef.current.value} ${cognomeRef.current.value}`,
                email: emailRef.current.value,
                telefono: telefonoRef.current.value,
                ip_address: ipAddress,
                privacy_chk_1: privacyVal ? 1 : 0,
                privacy_chk_2: newsletterVal ? 1 : 0,
                privacy_chk_3: marketingVal ? 1 : 0,
            }).then((d) => {
                // console.log(d);
                if (d.status ===  200 && Number.isInteger(d.data)) {
                    setSendResponse({ status: true, desc: `Ben fatto.\nA breve il nostro staff si occuperà di verificare le migliori offerte che fanno al tuo caso e ti ricontatteranno quanto prima.` });
                    // alert("Riceverai il codice via mail");
                }
                setSendingStatus(false);
            }).catch((e) => {
                setSendResponse({ status: false, desc: "Si è verificato un errore" });
                // alert("Si è verificato un errore");
                setSendingStatus(false);
            });

            // Invia i dati per l'invio mail tramite Make.com
            axios.post(`https://hook.eu1.make.com/n47k4jvs4xa6p2weuybblifpp417rniu`, {
                nominativo: `${nomeRef.current.value} ${cognomeRef.current.value}`,
                email: emailRef.current.value,
                telefono: telefonoRef.current.value,
                ip_address: ipAddress,
                privacy_chk_1: privacyVal ? 1 : 0,
                privacy_chk_2: newsletterVal ? 1 : 0,
                privacy_chk_3: marketingVal ? 1 : 0,
            }).then((d) => {
                // console.log(d);
                // if (d.status ===  200 && Number.isInteger(d.data)) {
                //     setSendResponse({ status: true, desc: `Ben fatto.\nA breve il nostro staff si occuperà di verificare le migliori offerte che fanno al tuo caso e ti ricontatteranno quanto prima.` });
                //     // alert("Riceverai il codice via mail");
                // }
                // setSendingStatus(false);
            }).catch((e) => {
                // setSendResponse({ status: false, desc: "Si è verificato un errore" });
                // // alert("Si è verificato un errore");
                // setSendingStatus(false);
            });
        }).catch((e) => {
            // console.log(e);
            // setSendingStatus(false);
        })
    }

    function handleOpenModal() {
        setModalOpen(true)
    }

    const checkEmail = e => {
        setValidEmail(e.target.validity.valid);
    }

    const checkTel = e => {
        setTelefonoVal(e.target.validity.valid);
    }

    function handleVerificationSuccess(token, ekey) {
        setCaptchaVal(true);
    }

    return (
        <Box sx={{ px: { xs: 1, md: 5 }, py: { xs: 3, md: 5 } }}>
            <Container maxWidth="sm">
                {sendResponse && !sendResponse.status ?
                    <Paper variant="outlined" sx={{ p: 5, textAlign: 'center' }}>
                        <img src={logoImg} style={{ width: "80%", maxWidth: 200}} alt='Compara Energia - Logo' />
                        <Typography component='h5' variant='h5' sx={{ fontWeight: 'bold', textTransform: 'uppercase', mt:4 }}>Risparmia sulla tua bolletta!<br />
                        Confronta ora le offerte luce e gas</Typography>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} xl={6}>
                                <TextField
                                    id="field_nome"
                                    label="Nome"
                                    variant="outlined"
                                    placeholder="Nome"
                                    type="text"
                                    inputRef={nomeRef}
                                    sx={{ my: 2, width: 1 }}
                                    required
                                    // inputProps={{ pattern: EMAIL_REGEX }}
                                    // helperText={validEmail === false ? 'Inserisci un indirizzo e-mail valido' : ''}
                                    // error={validEmail === false ?? false}
                                    // onBlur={checkEmail}
                                />
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TextField
                                    id="field_cognome"
                                    label="Cognome"
                                    variant="outlined"
                                    placeholder="Cognome"
                                    type="text"
                                    inputRef={cognomeRef}
                                    sx={{ my: 2, width: 1 }}
                                    required
                                    // inputProps={{ pattern: EMAIL_REGEX }}
                                    // helperText={validEmail === false ? 'Inserisci un indirizzo e-mail valido' : ''}
                                    // error={validEmail === false ?? false}
                                    onBlur={checkEmail}
                                />
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TextField
                                    id="field_telefono"
                                    label="Telefono"
                                    variant="outlined"
                                    placeholder="Telefono"
                                    type="text"
                                    inputRef={telefonoRef}
                                    sx={{ my: 2, width: 1 }}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+39</InputAdornment>,
                                    }}
                                    inputProps={{ pattern: TEL_REGEX }}
                                    helperText={validTel === false ? 'Inserisci il tuo numero di telefono senza spazi nè caratteri speciali' : ''}
                                    error={validTel === false ?? false}
                                    onBlur={checkTel}
                                />
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TextField
                                    id="field_email"
                                    label="E-mail"
                                    variant="outlined"
                                    placeholder="La tua e-mail"
                                    type="email"
                                    inputRef={emailRef}
                                    sx={{ my: 2, width: 1 }}
                                    inputProps={{ pattern: EMAIL_REGEX }}
                                    helperText={validEmail === false ? 'Inserisci un indirizzo e-mail valido' : ''}
                                    error={validEmail === false ?? false}
                                    onBlur={checkEmail}
                                />
                            </Grid>
                        </Grid>

                        <FormGroup sx={{ textAlign: "left" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // color="success"
                                        value="privacy"
                                        ref={privacyRef}
                                        checked={privacyVal}
                                        onChange={() => setPrivacyVal(!privacyVal)}
                                        required
                                    />
                                }
                                label="Acconsento al trattamento dei dati personali ai fini dell'erogazione del servizio richiesto"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // color="success"
                                        value="newsletter"
                                        ref={newsletterRef}
                                        checked={newsletterVal}
                                        onChange={() => setNewsletterVal(!newsletterVal)}
                                    />
                                }
                                label="Voglio iscrivermi alla newsletter per non perdere novità e promozioni"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // color="success"
                                        value="marketing"
                                        ref={marketingRef}
                                        checked={marketingVal}
                                        onChange={() => setMarketingVal(!marketingVal)}
                                    />
                                }
                                label="Acconsento al trattamento dei miei dati per comunicazioni commerciali, arricchimento e invio a soggetti terzi"
                            />
                        </FormGroup>

                        <Box sx={{ mt: 2, textAlign: "left", width: 1 }}>
                            <Link variant="link-small" color='secondary.main' onClick={handleOpenModal}>{`Leggi la nostra "Informativa Privacy"`}</Link>
                        </Box>

                        {sendResponse.status === false ?
                            <Paper variant="outlined" sx={{ p: 2, textAlign: 'center', mt: 2, backgroundColor: '#ed6c02', borderColor: "#e65100", color: "#fff", fontWeight: "bold", display: 'flex', justifyContent: "center", alignItems: 'center', gap: 2 }}>
                                <WarningAmber sx={{ fontSize: 80 }} />
                                <Typography component='p' variant='p' sx={{ textAlign: "center" }}>{sendResponse.desc}</Typography>
                            </Paper>
                            : null}

                        <FormGroup sx={{ mt: 2 }}>
                            <HCaptcha
                                sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                                onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                                onError={err => console.log(err)}
                            />
                        </FormGroup>

                        {!sendingStatus ?
                            <Button variant='contained' type='button' color="accent" sx={{ mt: 3, px: 5 }} onClick={handleClick} disabled={!enableSend}>Richiedi</Button>
                            :
                            <Box mt={3}>
                                <CircularProgress color="accent" />
                                <Typography component='p' variant='p' sx={{ textAlign: "center", fontWeight:'bold' }}>Stiamo inviando la tua richiesta, attendi qualche istante...</Typography>
                            </Box>
                            }
                    </Paper>
                    :
                    <Paper elevation={2} sx={{ p: 2, textAlign: 'center', mt: 2, backgroundColor: '#4caf50', borderColor: "#1b5e20", color: "#fff", fontWeight: "bold", display: 'flex', justifyContent: "center", alignItems: 'center', gap: 2 }}>
                        <ThumbUpOffAlt sx={{ fontSize: 80 }} />
                        <Typography component='p' variant='p' sx={{ textAlign: "center", display: "block" }}>{sendResponse.desc}</Typography>
                    </Paper>
                }
            </Container>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-privacy-title"
                aria-describedby="modal-privacy-description"
                sx={{
                    py: { xs: 1, md: 3 },
                    backgroundColor: '#00000044'
                }}
            >
                <Paper elevation={4} sx={{ p: { xs: 2, md: 5 }, position: "relative" }}>
                    <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={() => setModalOpen(false)}><HighlightOff /></IconButton>
                    <Typography id="modal-privacy-title" variant="h6" component="p">Sito www.comparatoreenergia.it nella titolarit&agrave; di JUNTOS SRL</Typography>
                    <Typography id="modal-privacy-description" variant="privacy-description" sx={{ mt: 2 }}>
                        <p><strong>Premessa</strong></p>
                        <p>Per&nbsp;<strong>JUNTOS SRL</strong>, societ&agrave; titolare del portale<strong> www.comparatoreenergia.it</strong> la tutela della privacy dei propri utenti &egrave; molto importante. Abbiamo quindi deciso di elaborare un documento di dettaglio relativo alle privacy policy adottate. Tale documento descrive, tra l&rsquo;altro, quali dati personali raccogliamo, le finalit&agrave; e le modalit&agrave; di trattamento adottate, e a cui sono destinati i dati personali degli utenti, nonch&eacute; le misure di sicurezza adottate per la protezione degli stessi.</p>
                        <p>Si tratta di un&rsquo;informativa resa ai sensi dell&rsquo;art. 13 del Regolamento UE 2016/679 &ndash; Regolamento generale sulla Protezione dei Dati Personali (di seguito, "<strong>Regolamento</strong>") &ndash; a coloro che interagiscono con i nostri servizi web accessibili per via telematica all&rsquo;indirizzo <strong>www.comparatoreenergia.it</strong> e (di seguito definito il &ldquo;Sito&rdquo;,). Con la presente, si informano gli utenti che i dati personali forniti a Juntos srl potranno formare oggetto di trattamento, nel rispetto della normativa sopra richiamata e degli obblighi di riservatezza.</p>
                        <p>L&rsquo;informativa &egrave; resa solo per il Sito e non anche per altri siti web eventualmente consultati dall&rsquo;utente tramite link. Inoltre, questa informativa generale non si riferisce agli specifici trattamenti di dati che potranno essere effettuati, all&rsquo;interno del Sito, da diversi Titolari del trattamento per specifiche finalit&agrave;, come indicate nelle apposite informative dagli stessi rilasciate.</p>
                        <p>Il Sito &egrave;, infatti, un portale che contiene vari strumenti di calcolo, alcuni di terzi. A titolo esemplificativo i servizi di preventivazione energetica (luce e gas), saranno forniti dall&rsquo;autonomo Titolare del trattamento Juntos srl, come da informative presentate al momento della fruizione del servizio.</p>
                        <p>Secondo le norme del Regolamento, i trattamenti effettuati da Juntos srl saranno improntati ai principi di liceit&agrave;, correttezza, trasparenza, limitazione delle finalit&agrave; e della conservazione, minimizzazione dei dati, esattezza, integrit&agrave; e riservatezza.</p>
                        <p><strong>Il Titolare del Trattamento e il Responsabile della Protezione dei Dati</strong></p>
                        <p>A seguito della semplice consultazione del Sito, possono essere trattati dati relativi a persone identificate o identificabili. Il Titolare del trattamento (di seguito, "Titolare") &egrave; la societ&agrave; Juntos srl, con sede legale in Via Trieste 21, Catania (Ct), P.iva 05849510879.</p>
                        <p>Il Responsabile per la Protezione dei Dati (di seguito, "<strong>DPO</strong>") &egrave; raggiungibile all&rsquo;indirizzo dpo@comparatoreenergia.it</p>
                        <p><strong>Dati trattati</strong></p>
                        <p>A seguito della navigazione sul Sito, La informiamo che il Titolare tratter&agrave; i dati personali, che potranno essere costituiti da un identificativo come il nome, un numero di identificazione, un identificativo online, uno o pi&ugrave; elementi caratteristici della identit&agrave; fisica, economica, culturale o sociale idonea a rendere il soggetto interessato identificato o identificabile.</p>
                        <p><strong>Dati di navigazione</strong></p>
                        <p>I sistemi informatici e le procedure software preposte al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione &egrave; implicita nell'uso dei protocolli di comunicazione di Internet.</p>
                        <p>Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati ma che, per loro stessa natura, potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.</p>
                        <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei dispositivi utilizzati dagli utenti che si connettono al Sito, gli indirizzi in notazione URI (<em>Uniform Resource Identifier</em>) delle risorse richieste, l'orario della richiesta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell'utente.</p>
                        <p>Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'utilizzo del Sito, e per controllare il corretto funzionamento (si veda anche il paragrafo sui Cookie). I dati potrebbero essere utilizzati dalle autorit&agrave; competenti per l'accertamento di responsabilit&agrave; in caso di ipotetici reati informatici ai danni del Sito.</p>
                        <p>Si informa che potranno essere inoltre trattati dati personali forniti volontariamente dall&rsquo;utente (per dettagli, si veda il paragrafo "<em>Dati forniti volontariamente dall'utente</em>").</p>
                        <p><strong>Dati forniti volontariamente dall'utente</strong></p>
                        <p>L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati sul Sito comporta la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonch&eacute; degli eventuali altri dati personali inseriti nella comunicazione. I dati personali sono altres&igrave; raccolti in occasione della registrazione sul Sito e, con il consenso dell'utente interessato, al momento della richiesta di preventivo saranno utilizzati da Juntos srl per la creazione di un'apposita pagina anagrafica in cui verr&agrave; riportato lo storico dei preventivi e dei servizi fruiti dallo stesso utente.</p>
                        <p>La raccolta dei dati personali contrassegnati da asterisco, nella scheda di registrazione o durante l'uso dei servizi, &egrave; necessaria per consentire all'utente di accedere alle funzionalit&agrave; del Sito indicate al momento della registrazione.</p>
                        <p>I dati personali saranno conservati, in esecuzione del servizio reso, per consentirgli di accedere nuovamente ai preventivi precedentemente compilati e/o all'area personale del portale. Il conferimento di questi dati &egrave; facoltativo, ma il rifiuto di fornirli comporta l'impossibilit&agrave; per l'utente di ottenere la registrazione al Sito e l'utilizzo dei servizi riservati agli utenti registrati.</p>
                        <p>Inoltre, si informa che i dati personali identificativi e di contatto volontariamente forniti nel corso della registrazione al Sito o nell&rsquo;uso di alcuni dei servizi in esso offerti, potranno, con il consenso specifico e distinto dell'interessato, essere trasmessi da Juntos srl ad alcuni partner selezionati per loro iniziative commerciali e di marketing, con strumenti automatizzati (email, SMS, fax, MMS, messaggi su social network, sistemi di messaggistica istantanea e autorisponditori) e/o non automatizzati (posta cartacea, telefono con operatore).</p>
                        <p>I dati di recapito forniti a Juntos srl potrebbero essere utilizzati, con il consenso dell'utente interessato, per inviare all'utente stesso informazioni promozionali riguardanti servizi e/o prodotti propri e/o di terzi, con strumenti automatizzati (email, SMS, fax, MMS, messaggi su social network, sistemi di messaggistica istantanea e autorisponditori) e/o non automatizzati (posta cartacea, telefono con operatore), ferma restando la facolt&agrave;, in capo all'utente, di opporsi all'invio di tali comunicazioni in qualsiasi momento, potendo altres&igrave; selezionare con quali strumenti essere contattato o meno.</p>
                        <p>Infine, tutti i dati (di navigazione e forniti volontariamente) potrebbero essere trattati per perseguire un legittimo interesse del titolare o di un terzo quale, ad esempio, la protezione da truffe, nonch&eacute; per far valere e difendere un diritto in sede giudiziaria.</p>
                        <p><strong>Cookies</strong></p>
                        <p>Il Sito utilizza cookie, per leggere la cookie policy e tutti i dettagli riguardo i cookie usati sul sito clicca&nbsp;qui.</p>
                        <p><strong>Modalit&agrave; di trattamento</strong></p>
                        <p>I dati personali sono trattati, anche con strumenti elettronici ed automatizzati, per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati, tra cui il protocollo sicuro https per alcune parti riservate del sito e delle applicazioni, e le protezioni da accessi abusivi nei server e negli altri elaboratori in uso.</p>
                        <p><strong>Finalit&agrave; e Base Giuridica del trattamento</strong></p>
                        <p>Il trattamento, dietro specifico consenso ove necessario, &egrave; caratterizzato dalle seguenti finalit&agrave;:</p>
                        <ul>
                        <li>consentire l'erogazione dei servizi richiesti, ossia: (i) l'erogazione dei servizi di comparazione offerti da Juntos srl e dalle altre societ&agrave; del Gruppo Juntos srl la gestione del contatto da parte di Juntos srl o altra societ&agrave; del Gruppo Juntos srl, ovvero di soggetti dalle stesse appositamente autorizzati per la fornitura del servizio di consulenza e assistenza sul servizio selezionato; (iii) l'accesso all'area riservata del Sito;</li>
                        <li>rispondere a richieste di assistenza e/o di informazioni;</li>
                        <li>assolvere eventuali obblighi di legge, contabili e fiscali;</li>
                        <li>marketing e profilazione. I dati conferiti potranno essere trattati, previo esplicito e specifico consenso, per l'invio di comunicazioni promozionali e di marketing, incluso l'invio di newsletter e ricerche di mercato, attraverso strumenti automatizzati (SMS, MMS, email, notifiche push, fax, sistemi di messaggistica istantanea) e non automatizzati (posta cartacea, telefono con operatore), nonch&eacute; per l'analisi dei dati anagrafici dell'utente, delle scelte d'acquisto e delle preferenze comportamentali sul Sito, al fine di meglio strutturare comunicazioni e proposte commerciali personalizzate, per effettuare analisi generali e per attivit&agrave;? di profilazione.</li>
                        </ul>
                        <p>La base giuridica del trattamento di dati personali per le finalit&agrave; di cui alle lettere da a) a d) &egrave; l'art. 6.1 lett. b) e c) del Regolamento in quanto i trattamenti sono necessari all'erogazione dei servizi o per il riscontro di richieste dell'interessato, in conformit&agrave; a quanto previsto dalle Condizioni generali di utilizzo del servizio sottoscritte dall'utente, rappresentando altres&igrave; un trattamento necessario per adempiere ad un obbligo di legge in capo al Titolare.</p>
                        <p>I dati conferiti per le finalit&agrave; di cui alla lettera e) potranno essere trattati, previo esplicito e specifico consenso, per l'invio di comunicazioni promozionali e di marketing, incluso l'invio di newsletter e ricerche di mercato, attraverso strumenti automatizzati (SMS, MMS, email, notifiche push, fax e sistemi di messaggistica istantanea) e non automatizzati (posta cartacea, telefono con operatore), nonch&eacute; per l'analisi dei dati anagrafici, delle scelte d'acquisto e delle preferenze comportamentali sul Sito, al fine di meglio strutturare comunicazioni e proposte commerciali personalizzate, per effettuare analisi generali e per attivit&agrave; di profilazione. La base giuridica del trattamento dei dati per tali finalit&agrave; e? l'art. 6.1 lett. a) del Regolamento.</p>
                        <p>I trattamenti di profilazione commerciale e di marketing diretto sono facoltativi e dipendono dalla libera scelta dell'utente; pertanto, il mancato conferimento dei consensi per tali finalit&agrave; non pregiudica la fruizione dei servizi.</p>
                        <p>Per i trattamenti effettuati ai fini di invio diretto di proprio materiale pubblicitario o di propria vendita diretta o per il compimento di proprie ricerche di mercato o di comunicazioni commerciali in relazione a prodotti o servizi del Titolare analoghi a quelli acquistati, Facile.it S.p.A. con socio unico pu&ograve; utilizzare, senza il consenso dell'interessato, gli indirizzi di posta elettronica e di posta cartacea ai sensi e nei limiti consentiti dall'art. 130.4 del Codice Privacy (D. Lgs. 196/2003) e dalle Linee Guida del Garante Privacy del 4 luglio 2013 (punto 2.7). La base giuridica del trattamento dei dati per tale finalit&agrave; e? l'art. 6.1 f) del Regolamento. L'interessato ha la possibilit&agrave; di opporsi a tale trattamento in ogni momento, inizialmente o in occasione di successive comunicazioni, in maniera agevole e gratuitamente anche scrivendo all'indirizzo di posta elettronica sopra indicato, nonch&eacute; di ottenere un immediato riscontro che confermi l'interruzione di tale trattamento (art. 15 Regolamento).</p>
                        <p>Juntos srl potr&agrave; trattare i dati personali in forma anonimizzata e aggregata, per analisi ed elaborazioni, comprese attivit&agrave; di &ldquo;debugging&rdquo;, volte a migliorare i prodotti, i servizi e la proposizione commerciale, nel rispetto dei principi del Regolamento e per il perseguimento di un proprio legittimo interesse, ai sensi dell'art. 6.1.f) del Regolamento, in maniera tale che il trattamento non produca effetti giuridici e non incida in modo analogo significativamente sull'utenza. In ogni caso, l'interessato potr&agrave; opporsi in qualunque momento a tale trattamento contattando il Titolare ai recapiti indicati.</p>
                        <p>Ove necessario, specifiche informative di sintesi sul trattamento dei dati personali e richieste di consenso saranno progressivamente riportate o visualizzate nelle pagine del Sito predisposte per particolari servizi.</p>
                        <p><strong>Trasmissione dei dati a terzi</strong></p>
                        <p>I dati personali non saranno trasmessi a terzi, fatta eccezione per gli autonomi titolari del trattamento appartenenti alle seguenti categorie:</p>
                        <ul>
                        <li>Compagnie assicurative e altri intermediari assicurativi;</li>
                        <li>Fornitori di servizi di comunicazione e/o internet casa e/o loro intermediari;</li>
                        <li>Fornitori di servizi luce e gas e/o loro intermediari;</li>
                        <li>Finanziarie e Istituti di Credito e/o loro intermediari.</li>
                        <li>Societ&agrave; offerenti il servizio di noleggio a lungo termine.</li>
                        </ul>
                        <p>La trasmissione avviene per finalit&agrave; strettamente e direttamente connesse all'esecuzione del loro servizio non promozionale di preventivazione e/o di trattativa contrattuale richiesto dall'utente interessato. L'interessato e? quindi consapevole che, richiedendo i servizi di preventivazione e/o precontrattuali tramite il Sito, potrebbe vedere i suoi dati comunicati ai soggetti sopra indicati per esclusive finalit&agrave; di esecuzione del servizio anche in mancanza di un consenso specifico, ai sensi dell'art. 6 lett. b) del Regolamento, e che &ndash; con riferimento ai trattamenti eseguiti da tali soggetti terzi autonomi titolari del trattamento &ndash; faranno fede le loro autonome informative ai sensi degli articoli 13 o 14 del Regolamento presentate contestualmente alla raccolta dei dati (per esempio, nella fase di preventivazione anche interna al Sito).</p>
                        <p>Inoltre, i dati personali forniti dagli utenti saranno trasmessi a:</p>
                        <ul>
                        <li>soggetti che agiscono tipicamente in qualit&agrave; di responsabili del trattamento ex art.28 del Regolamento, ossia: (i) persone, societ&agrave; o studi professionali che prestano attivit&agrave; di assistenza e consulenza al Titolare in materia contabile, amministrativa, legale, tributaria, finanziaria e di recupero crediti relativamente alla erogazione dei servizi offerti; (ii) soggetti con i quali sia necessario interagire per l'erogazione dei servizi (ad esempio di hosting provider); (iii) soggetti delegati a svolgere attivit&agrave; di manutenzione tecnica (inclusa la manutenzione degli apparati di rete e delle reti di comunicazione elettronica); (iv) ovvero soggetti, anche appartenenti al Gruppo Juntos srl cui &egrave; delegata l'esecuzione delle chiamate telefoniche con operatore in entrata o in uscita. Collettivamente questi soggetti vengono definiti &ldquo;destinatari&rdquo;. L'elenco dei Responsabili del trattamento, oltre a quelli specificamente indicati sopra, pu&ograve; essere richiesto al Titolare scrivendo all'indirizzo indicato nel presente documento;</li>
                        <li>soggetti, enti o autorit&agrave;, autonomi Titolari del trattamento, a cui sia obbligatorio comunicare i dati personali del cliente interessato, in forza di disposizioni di legge o di ordini delle autorit&agrave;;</li>
                        <li>soggetti autorizzati dal Titolare ex art. 29 del Regolamento, necessari a svolgere attivit&agrave; strettamente correlate all&rsquo;erogazione dei servizi richiesti, e che si siano impegnati alla riservatezza o abbiano un adeguato obbligo legale di riservatezza;</li>
                        </ul>
                        <p>Come gi&agrave; chiarito, a proposito delle possibili finalit&agrave; dei trattamenti in presenza di consensi specifici, liberi e informati degli utenti interessati, da questi rilasciati in specifiche sezioni del Sito, i dati personali potrebbero essere comunicati ad alcuni partner selezionati da Juntos srl, le cui categorie vengono specificate nelle apposite e specifiche domande di consenso, per loro iniziative commerciali e di marketing, con strumenti automatizzati (email, SMS, fax, MMS, messaggi su social network, sistemi di messaggistica istantanea e autorisponditori) e/o non automatizzati (posta cartacea, telefono con operatore).</p>
                        <p><strong>Conservazione</strong></p>
                        <p>I dati personali saranno conservati solo per il tempo necessario per il perseguimento delle finalit&agrave; per cui sono raccolti, nel rispetto del principio di minimizzazione di cui all'articolo 5.1 c) del Regolamento. Trattandosi di un servizio continuativo periodico, i dati verranno trattati dal Titolare fino a quando il citato servizio non verr&agrave; cessato dall'utente (per maggiori informazioni, si vedano le&nbsp;<em>Condizioni Generali di utilizzo del Sito</em>) e comunque entro il limite temporale di 10 anni dall'ultima azione positiva effettuata dall'interessato: trascorso tale periodo i dati del cliente verranno automaticamente cancellati dai nostri sistemi, a meno che il cliente non intraprenda un'azione positiva per mantenere attivo il proprio profilo. Il servizio erogato da Juntos srl &egrave; totalmente gratuito per la propria utenza. Maggiori informazioni sono disponibili contattando il Titolare ai riferimenti di contatto indicati nel presente documento.</p>
                        <p><strong>Diritti degli interessati</strong></p>
                        <p>Ai sensi degli articoli 15 e seguenti del Regolamento, l'interessato ha il diritto di chiedere, in qualunque momento, l'accesso ai dati personali, la rettifica o la cancellazione degli stessi, la limitazione del trattamento nei casi previsti dall'art. 18 del Regolamento, l'ottenimento dei dati in un formato strutturato, di uso comune e leggibile da dispositivo automatico nei casi previsti dall'art. 20 del Regolamento. In ogni momento, l'interessato ha la possibilit&agrave; di: revocare il consenso prestato ex art. 7 del Regolamento; proporre reclamo all'autorit&agrave; di controllo competente (Garante Privacy) ai sensi dell'art. 77 del Regolamento, qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore.</p>
                        <p>L'interessato ha la possibilit&agrave; di formulare una richiesta di opposizione al trattamento dei dati personali ex art. 21 del Regolamento, nella quale dare evidenza delle ragioni che giustifichino l'opposizione: il Titolare si riserva di valutare l'istanza, che non verrebbe accettata in caso di esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, diritti e libert&agrave; dell'interessato.</p>
                        <p>Per qualsiasi richiesta e/o comunicazione riguardante la presente informativa ovvero il trattamento effettuato, l'utente potr&agrave; contattare Juntos srl unico al seguente indirizzo email dedicato: segnalazioni-privacy@comparatoreenergia.it</p>
                        <p>Se la richiesta riguardi un trattamento di dati effettuato da un altro titolare, l'utente dovr&agrave; fare riferimento all'informativa privacy da quest'ultimo rilasciata al momento della richiesta di preventivo effettuata, in quanto in tal caso Juntos srl unico non tratta i dati personali direttamente per l'esecuzione del preventivo.</p>
                    </Typography>
                </Paper>
            </Modal>
        </Box>
    )
}